$sidebarWidth: 250px;
:root {
  --primaryColor: #f88a4d;
  --secondaryColor: #4464ad;
  --bgColor: white;
  --textColor: #1f2937;
  --bgColorTransparent: #e0dddd;
  --textColor: #1f2937;
}

[data-theme="dark"] {
  --bgColor: #1f2937;
  --textColor: white;
  --bgColorTransparent: #5c5b5b;
}

.Toastify,
.Toastify__toast-container,
.Toastify__toast-container--top-right,
.Toastify__toast {
  z-index: 90999999999 !important;
}

@font-face {
  font-family: Poppins-Regular;
  src: url("../../public/font/Poppins-Regular.ttf");
}
@font-face {
  font-family: Poppins-Bold;
  src: url("../../public/font/Poppins-Bold.ttf");
}
* {
  box-sizing: border-box;
}
html,
body {
  background-color: var(--bgColor);
  overflow-x: hidden;
}

p,
span,
sub,
a,
button,
input,
textarea {
  font-family: Poppins-Regular;
}
h1,
h2,
h3,
h4,
h5,
b,
label {
  font-family: Poppins-Bold;
}
html,
body,
h1,
h2,
h3,
h4,
h5,
p,
ul,
li {
  margin: 0;
  padding: 0;
}
ul,
ol,
li {
  list-style: none;
}
img {
  object-fit: cover;
}
a {
  text-decoration: none;
  color: inherit;
}
input,
textarea,
button {
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

input,
textarea {
  border: solid 1px rgba($color: var(--textColor), $alpha: 0.3) !important;
  border-radius: 4px;
  color: grey;
  padding: 15px 20px;
  transition: all 0.6s;
  &:focus {
    outline: 0;
    border-color: var(--primaryColor);
    color: var(--primaryColor);
    &::placeholder {
      color: var(--primaryColor);
    }
  }
}
textarea {
  height: 200px;
}

.flex {
  display: flex;
  &.f-column {
    flex-direction: column;
  }
  &.f-wrap {
    flex-wrap: wrap;
  }
  &.ai-c {
    align-items: center;
  }
  &.jc-c {
    justify-content: center;
  }
  &.jc-se {
    justify-content: space-evenly;
  }
}

a {
  transition: all 0.6s;
}

.btn {
  padding: 15px 30px;
  border-radius: 8px;
  cursor: pointer;
}
.backgroundButton {
  background-color: var(--primaryColor);
  color: var(--bgColor);
}
.borderButton {
  border: solid 1px var(--primaryColor);
  color: var(--primaryColor);
}
.btnStyle1,
.btnStyle2,
.btnStyle3 {
  padding: 15px 30px;
  border-radius: 30px;
}
.btnStyle1 {
  border: solid 2px white;
  color: white;
  &:hover {
    color: black !important;
    background-color: white;
  }
}

.btnStyle2 {
  background-color: white;
  color: black;
  border: solid 2px white;
  &:hover {
    color: white;
    border-color: white;
    background-color: transparent;
  }
}
.btnStyle3 {
  background-color: var(--primaryColor);
  color: white;
}

.sectionLoader {
  width: 90%;
  margin: auto;
  padding: 150px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primaryColor);
  font-size: 100px;
}
a,
button {
  font-size: 16px;
}

@media screen and (max-width: 758px) {
  .btn {
    padding: 10px 20px;
  }
}
