footer {
  padding: 50px 0;
  color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
  justify-content: space-evenly;
  position: relative;
  background-color: #121314;
  h2 {
    margin-bottom: 25px;
  }
  img {
    width: 150px;
    margin: 25px 0;
  }
  section {
    width: 25%;
  }
  .footer-logoAndContactInfos {
    gap: 15px 0;
    a {
      font-size: 20px;
      span {
        color: var(--primaryColor);
      }
    }
  }
  .footer-fastLinks {
    div {
      gap: 15px 0;
    }
  }
  .footer-newsLetter {
    form {
      margin: 25px 0;
      display: flex;
      width: 100%;
      input {
        width: 100%;
        &::placeholder {
          font-size: 18px;
        }
      }
      button {
        width: 40%;
        font-size: 18px;
        background-color: var(--primaryColor);
        color: white;
      }
    }
  }

  hr {
    width: 90%;
    border-color: var(--secondaryColor);
    opacity: 0.4;
  }
  .footer-copy {
    width: 90%;
    margin: auto;
    justify-content: space-between;

    div {
      gap: 0 10px;
      svg {
        font-size: 30px;
      }
    }
  }
}

@media screen and (max-width: 758px) {
  footer {
    flex-direction: column;
    align-items: center;
    section {
      width: 90%;
    }
  }
}
