.contact {
  padding: 100px 0;
  h1 {
    color: var(--primaryColor);
  }
  color: var(--textColor);
}
.titre-contact {
  text-align: center;
  font-size: 50px;
  padding: 30px 0;
}

.formulaire {
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin: auto;

  .formulaire-input {
    width: 50%;
    form {
      width: 100%;
    }
  }
  .formulaire-img {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 90%;
      border-radius: 0 30px 30px 0;
      object-fit: cover;
      height: 100%;
      margin: auto;
    }
    .f-img-bottom {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin: 25px 0;
      div {
        display: flex;
        flex-direction: column;

        p {
          margin-top: 10px;
          display: flex;
          font-size: 20px;
          align-items: center;
          justify-content: center;

          svg {
            font-size: 30px;
            color: var(--primaryColor);
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .contact {
    width: 100%;
    flex-direction: column-reverse;
    .titre-contact {
      text-align: center;
      font-size: 25px;
      padding: 25px 0;
      width: 90%;
      margin: auto;
    }

    .formulaire {
      width: 100%;
      display: flex;
      flex-direction: column;

      .formulaire-input {
        width: 90%;
        margin: auto;
        order: 2;
        form {
          width: 100%;
          margin: auto;
          label {
            font-size: 16px;
          }
          button {
            width: 60%;
            padding: 10px 8px;
            font-size: 18px;
            margin: auto;
            margin-bottom: 30px;
          }
        }
      }
      .formulaire-img {
        width: 85%;
        margin: auto;
        order: 1;
        .f-img-bottom {
          flex-direction: column;
          gap: 15px 0;
          div {
            align-items: flex-start;
          }
        }
      }
    }
  }
}
