.login {
  height: 100vh;
  width: 100%;
  background-color: var(--primaryColor);
  & > div {
    background-color: white;
    width: 40%;
    padding: 50px;
  }
}

.connectedMain {
  color: var(--textColor);
  margin-left: auto;
  width: calc(100% - $sidebarWidth);
  padding: 50px;
  transition: all 0.6s;
}
@media screen and (max-width: 1024px) {
  .login {
    & > div {
      width: 90%;
    }
  }

  .connectedMain {
    width: 100%;
  }
}
