nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 50px;
  position: fixed;
  z-index: 10000000000000;
  transition: all 0.6s;
  background-color: var(--bgColor);
  &.navScrolled {
    background-color: var(--bgColor);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  .navLogo {
    font-size: 50px;
    font-weight: bold;
    display: flex;
    align-items: center;
    img {
      width: 220px;
    }
  }
  .navLinks {
    display: flex;
    align-items: center;
    gap: 0 25px;
    a {
      font-size: 20px;
      text-decoration: underline;
      text-underline-offset: 20px;
      text-decoration-color: transparent;
      color: var(--textColor);
      &:hover {
        color: var(--primaryColor);
        text-decoration-color: var(--primaryColor);
      }
    }
    span {
      font-size: 30px;
      color: var(--textColor);
      cursor: pointer;
    }
  }
  .navSocials {
    display: flex;
    gap: 0 10px;
    a {
      background-color: var(--textColor);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 100%;
      color: var(--bgColor);
      cursor: pointer;
      &:hover {
        background-color: var(--primaryColor);
        color: white;
      }
    }
  }

  .navHamburger,
  .navHamburgerContent {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  nav {
    padding: 20px 30px;

    & > .navLinks,
    & > .navSocials {
      display: none;
    }
    .navHamburger,
    .navHamburgerContent {
      display: flex;
    }
    .navHamburger {
      background-color: var(--primaryColor);
      color: white;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .navHamburgerContent {
      position: absolute;
      width: 100%;
      max-height: 0;
      overflow: hidden;
      top: 100%;
      left: 50%;
      transform: scaleY(0) translateX(-50%);
      transform-origin: 100% 0;
      transition: all 0.6s;
      gap: 25px 0;
      background-color: var(--bgColor);
      // background-color: rgba($color: var(--bgColor), $alpha: 0.5);
      // backdrop-filter: blur(10px);

      opacity: 0;
      .navLinks {
        flex-direction: column;
        gap: 20px 0;
        padding: 15px 30px;
      }
      .navSocials {
        padding: 0 30px 15px 30px;
      }
      &.navHamburgerContenVisible {
        opacity: 1;
        transform: scaleY(1) translateX(-50%);

        max-height: 500px !important;
        overflow: initial !important;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      }
    }
  }
}
