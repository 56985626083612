form {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  width: 100%;
  padding: 25px 0;
  overflow-x: hidden;
  justify-content: center;
  align-items: center;
}
.errorMessageOnField {
  color: red;
}
.formButton {
  margin: auto;
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    border-radius: 10px;
    background-color: var(--primaryColor);
    color: white;
    width: 100%;
    cursor: pointer;
    &:disabled {
      background-color: rgba(0, 0, 0, 0.16) !important;
      color: grey;
    }
    svg {
      font-size: 16px;
    }
  }
}
input:not([type="radio"]),
textarea {
  padding: 15px;
  border: none;
  background-color: white;
  border-radius: 10px;
  &:focus {
    border: 0;
    outline: 0;
  }
}
.formField {
  width: 95%;
  display: flex;
  flex-direction: column;
  gap: 6px 0;

  textarea {
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
  & > label {
    font-weight: 900;
  }
  input:not([type="radio"]) {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  }
  input[type="radio"] {
    border: 0px;
    width: 30px;
    height: 2em;
  }
}

.formFieldRadio {
  & > div {
    gap: 10px;
    input {
      display: none;
      &:checked + p {
        background-color: var(--primaryColor);
        color: white;
      }
    }
    label {
      p {
        background-color: var(--bgColor);
        color: var(--textColor);
        padding: 3px 6px;
      }

      cursor: pointer;
      align-items: center;
      gap: 0 5px;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    }
  }
}

.formFieldFile {
  input {
    display: none;
  }
  label {
    span {
      background-color: var(--bgColor);
      border: solid 2px var(--primaryColor);
      color: var(--textColor);
      padding: 6px 12px;
      border-radius: 5px;
      cursor: pointer;
    }
  }
  article {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    p {
      background-color: var(--bgColor);
      color: var(--textColor);
      margin: 10px auto;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      span {
        max-width: 70%;
        word-break: break-all;
      }
      small {
        color: red;
      }
    }
  }
}

.formFieldPwd {
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  section {
    // position: relative;
    display: flex;
    .pwdViewIcon {
      width: 15%;
      background-color: #efefef;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 10px 10px 0;
      cursor: pointer;
    }
  }
}

.formFieldSelect {
  position: relative;

  cursor: pointer;
  b {
    padding: 15px;
  }
  section {
    overflow: hidden;
    z-index: 10000;
    background-color: var(--bgColorTransparent);
    color: var(--textColor);
    position: absolute;
    top: 96%;
    left: 0;
    width: 100%;
    span {
      padding: 15px;
      &:hover {
        background-color: var(--primaryColor);
      }
    }
  }
  b,
  section {
    background-color: var(--bgColorTransparent);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    color: var(--textColor);
  }
}

.multipleField {
  align-items: flex-start;
  justify-content: flex-start;
  & > button {
    background-color: rgba(0, 0, 0, 0.4) !important;
    padding: 10px 15px !important;
  }
  div {
    gap: 10px;
    p {
      background-color: #efefef;
      padding: 3px 6px;
      border-radius: 2px;
      gap: 0 15px;
      span {
        background-color: var(--primaryColor);
        color: var(--textColor);
        width: 30px;
        height: 30px;
        border-radius: 6px;
        cursor: pointer;
      }
    }
  }
  button {
    padding: 5px 10px;
  }

  & > button {
    background-color: var(--bgColor);
    color: var(--textColor);
  }
  article {
    width: 100%;
    input {
      width: 100%;
    }
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 0;
    button {
      background-color: var(--primaryColor);
    }
  }
}
