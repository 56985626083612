.faq {
  padding: 100px 0;
  h1 {
    font-size: 50px;
    font-weight: 400;
    text-align: center;
    color: var(--primaryColor);
  }
  p {
    text-align: center;
    color: var(--textColor);
  }

  .faq-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 70%;
    margin: 25px auto;
    gap: 15px 0;
  }
  .faq-button {
    grid-column: span 1;
    display: flex;
    gap: 12px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 20px 15px;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
    color: var(--textColor);
    background-color: rgba($color: #f88a4d, $alpha: 0.3);
    border-bottom: solid 1px var(--textColor);

    &:focus {
      outline: none;
    }
    .faq-icon {
      transition: transform 0.3s ease;
      width: 20px;
      height: 20px;
    }
    &.rotate .faq-icon {
      transform: rotate(180deg);
    }
    &.colorchange {
    }
  }
  .faq-text1 {
    padding: 10px;
    font-size: 14px;
    color: #ffffff;
    display: none;

    &.open {
      display: block;
      color: var(--primaryColor);
      font-size: 25px;
    }
  }
}

@media screen and (max-width: 752px) {
  .faq {
    width: 100%;
    h1 {
      margin-bottom: 10px;
    }

    .faq-text {
      width: 90%;
    }
  }
}
