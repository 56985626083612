.home {
  color: var(--textColor);
  background-repeat: no-repeat;
  h2 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 30px;
  }
  &
    > div:not(
      .homeBanner,
      .homeCoach,
      .home-whoIAm,
      .homeCustomerProof,
      .homeTestimonial
    ) {
    margin: 50px auto;
  }
  .homeBanner {
    padding: 100px 0 50px 0;
    min-height: 90vh;
    justify-content: space-evenly;
    align-items: center;
    background-color: rgba($color: #f88a4d, $alpha: 0.1);
    position: relative;

    .hb-text {
      gap: 25px 0;
      width: 40%;
      align-items: flex-start;
      word-break: break-all;
      b {
        font-size: 6vh;
      }
      h1 {
        font-size: 7vh;
        word-break: break-all;
      }
      div {
        gap: 5px 15px;
        width: 100%;
      }
      .hb-AbsoluteImg {
        width: 90%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .hb-img {
      width: 45%;
      height: 100%;
      overflow: hidden;
      .hbi-div {
        display: flex !important;
        align-items: center;
        justify-content: center;
        min-height: 90vh;

        img {
          z-index: 300;
          height: 100%;
          width: 100%;
          border-radius: 20px;
          overflow: hidden;
          object-fit: contain;
        }
      }
    }
  }

  .homeResultsStats {
    width: 85%;
    justify-content: space-evenly;
    .hRSText {
      width: 45%;
      .hRST-title {
        font-size: 50px;
        text-align: start;
        span {
          color: var(--primaryColor);
        }
      }
      p {
        font-size: 20px;
      }
    }

    section {
      justify-content: space-evenly;
      width: 45%;
      gap: 10px;
      // &.hRSNumber {
      //   & > div {
      //     width: 45%;
      //     background-color: red;
      //   }
      // }
      article {
        gap: 0 15px !important;
        padding: 50px 0;
        border-radius: 20px;
        width: 45%;
        & > span {
          color: var(--primaryColor);
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 50px;
        }
        h3 {
          font-size: 30px;
        }
      }
    }
  }
  .homeWhyUs {
    width: 90%;
    margin: auto;
    justify-content: space-evenly;
    align-items: center;
    .hwu-left {
      width: 55%;
      .slick-list {
        margin: -5px 0;
      }
      .slick-slide > div {
        padding: 5px 0;
      }
      article {
        padding: 50px 25px;
        display: flex !important;
        align-items: center;
        justify-content: space-evenly;
        border: solid 1px var(--bgColor);
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        &:hover {
          border-color: var(--primaryColor);
        }
        span {
          background-color: var(--primaryColor);
          width: 100px;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 60px;
          border-radius: 8px;
          color: white;
        }
        div {
          width: calc(100% - 150px);
          display: flex;
          flex-direction: column;
          gap: 10px 0;
          h3 {
            font-size: 25px;
          }
        }
      }
    }
    .hwu-right {
      width: 40%;
      .hwur-img {
        display: flex !important;
        justify-content: center;
        align-items: center;
        img {
          width: 80%;
          object-fit: contain !important;
          border-radius: 50px;
          overflow: hidden;
          box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
            rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
        }
      }
    }
  }

  .home-whoIAm {
    width: 100%;
    justify-content: space-around;
    background-color: #1f2937;
    padding: 50px 6%;
    color: white;
    div {
      width: 45%;
    }
    .hw-img {
      display: flex;
      justify-content: center;
      img {
        width: 70%;
        object-fit: contain;
      }
    }
    .hw-text {
      gap: 35px 0;
      justify-content: center;
      align-items: flex-start;
      h3 {
        font-size: 50px;
      }
      p {
        font-size: 20px;
      }
      section {
        margin-top: 30px;
      }
    }
  }

  .homeCoach {
    width: 100%;
    padding: 50px 6%;
    background-image: url("../../../public/images/banners/banner3.webp");
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    background-color: black;
    display: flex;
    justify-content: space-evenly;
    div {
      width: 45%;
    }
    .hc-text {
      align-items: flex-start;
      justify-content: center;
      gap: 30px 0;
      h3 {
        font-size: 30px;
      }
      p {
        font-size: 20px;
      }
      hr {
        height: 5px;
        width: 30%;
        background-color: var(--primaryColor);
        border: none;
        margin-left: 0;
      }
      section {
        width: 100%;
        h4 {
          font-size: 20px;
        }
        p {
          font-size: 20px;
        }
      }
    }
    .hc-img {
      display: flex;
      justify-content: center;
      img {
        width: 100%;
        object-fit: contain !important;
      }
    }
  }
  .homeModule {
    width: 80%;
    .slick-list {
      margin: 0 -15px;
    }
    .slick-slide > div {
      padding: 0 5px;
    }
    .slick-slide {
      &:nth-child(even) {
        .m-content {
          section:first-child {
            order: 2 !important;
          }
          section:last-child {
            order: 1 !important;
          }
        }
      }
    }
    .m-content {
      min-height: 80vh;
      display: flex !important;
      align-items: center;
      justify-content: space-evenly;

      section {
        width: 45%;
        img {
          width: 100%;
        }
        li {
          margin: 10px 0;
          gap: 0 10px;
          span {
            width: 20px;
            height: 20px;
            background-color: var(--primaryColor);
            border-radius: 6px;
            color: white;
            svg {
              font-size: 12px;
            }
          }
          p {
            max-width: 90%;
          }
        }
        &:first-child {
          display: flex;
          flex-direction: column;
          gap: 15px 0;
          span {
            font-size: 20px;
          }
          h3 {
            font-size: 25px;
          }
        }
      }
    }
  }
  .homeCustomerProof {
    padding: 80px 0;
    background-color: var(--primaryColor);
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      color: white;
    }
    & > div {
      width: 80%;
      margin: auto;
      .slick-list {
        margin: 0 -5px;
      }
      .slick-slide > div {
        padding: 0 5px;
      }

      img {
        border-radius: 20px;
        object-fit: contain;
        border: solid 5px black;
        margin-bottom: 50px;
      }
    }
    a {
      color: var(--primaryColor);
      background-color: #ffff;
      margin: auto;
      padding: 15px 30px;
      border-radius: 20px;
      text-align: center;
      display: block;
    }
  }

  .homePricing {
    width: 90%;
    div {
      width: 100%;
      justify-content: space-evenly;
      gap: 25px;
      .planCard {
        cursor: pointer;
        border: solid 1px rgba(245, 241, 241, 0.16);
        backdrop-filter: blur(1px);
        background-color: rgba(186, 178, 178, 0.16);

        border-radius: 20px;
        width: 350px;
        gap: 25px 0;
        transition: all 0.6s;
        padding-bottom: 25px;
        &:hover {
          transform: scale(1.05);
          background-color: rgba(186, 178, 178, 0.16);
          .pc-top {
            background-color: var(--primaryColor);
            color: white;
          }
        }
        .pc-top {
          padding: 50px;
          border: solid 1px var(--primaryColor);
          border-bottom-width: 10px;
          border-radius: 20px;
          transition: all 0.6s;
          p {
            font-size: 50px;
            sub {
              margin-top: auto;
              font-size: 20px;
            }
          }
        }
        .pc-bottom {
          padding: 0 30px;
          gap: 10px 0;
          li {
            gap: 0 10px;
            svg {
              color: var(--primaryColor);
            }
            p {
              max-width: 90%;
            }
          }
        }
        a {
          margin: 0 auto 25px auto;
        }
      }
    }
  }
}

.homeTestimonial {
  background-color: var(--primaryColor);
  padding: 40px 0;
  h2 {
    color: white;
    text-align: center;
  }
  & > section {
    width: 95%;
    margin: auto;
  }
  .slick-list {
    margin: 0 -5px;
  }
  .slick-slide > div {
    padding: 0 5px;
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 0 15px;
    margin: 15px 0;
    li {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      background-color: rgba(0, 0, 0, 0.16);
      z-index: 99999999;
      cursor: pointer;
      span {
        display: none;
      }
      &.slick-active {
        background-color: white;
      }
    }
  }

  .testimonialCard {
    background-color: var(--bgColor);
    color: var(--textColor);
    padding: 50px;
    border-radius: 20px;

    section {
      align-items: flex-start;
      justify-content: flex-start;
      p {
      }
      span {
        color: var(--primaryColor);
        font-size: 80px;
      }
    }

    & > div {
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 0 15px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 100%;
      }
      div {
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .home {
    .homeBanner {
      gap: 15px 0;
      height: initial;
      .hb-text {
        order: 2;
        width: 90%;
        h1 {
          font-size: 25px;
        }
        div {
          font-size: 12px;
          // gap: 5px;
        }
      }
      .hb-img {
        order: 1;
        width: 90%;
        .hbi-div {
          height: auto;
          min-height: initial;
          max-height: 50vh;
        }
      }
    }

    .homeResultsStats {
      flex-direction: column;
      gap: 15px 0;
      .hRSText {
        width: 100%;
        .hRST-title {
          font-size: 35px;
          text-align: start;
          span {
            color: var(--primaryColor);
          }
        }
        p {
          font-size: 16px;
          font-weight: 300;
        }
      }
      section {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        article {
          width: 100%;
        }
      }
    }

    .homeWhyUs {
      width: 90%;
      flex-direction: column;
      .hwu-left {
        width: 100%;
        max-height: 70vh;
        overflow: hidden;
        order: 2;
        article {
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 15px 0;
          span {
            margin-right: auto;
          }
          div {
            width: 100%;
          }
        }
      }
      .hwu-right {
        width: 100%;
        order: 1;
      }
    }

    .home-whoIAm {
      flex-direction: column;
      gap: 15px 0;
      .hw-text {
        width: 100%;
        order: 2;
        h3 {
          font-size: 30px;
        }
      }
      .hw-img {
        order: 1;
        width: 100%;
        img {
          width: 100%;
        }
      }
    }

    .homeModule {
      width: 80%;
      .m-content {
        flex-direction: column;
        section {
          width: 100%;
        }
      }
    }

    .homeCoach {
      flex-direction: column;
      & > div {
        width: 100%;
      }
      .hc-text {
        order: 2;
        align-items: flex-start;
        justify-content: center;
        gap: 15px 0;
        h3 {
          font-size: 18px;
        }

        hr {
          width: 40%;
        }
        section {
          h4 {
            font-size: 20px;
          }
          p {
            font-size: 20px;
          }
        }
      }
      .hc-img {
        order: 1;

        img {
          width: 100%;
        }
      }
    }

    .homePricing {
      div {
        gap: 50px 0;
        .planCard {
          width: 100%;
        }
      }
    }
  }

  .testimonialCard {
    padding: 25px 15px !important;

    section {
      flex-direction: column !important;
      align-items: flex-start;
      justify-content: flex-start;
      span {
        font-size: 40px !important;
      }
    }
    div {
      img {
        width: 50px !important;
        height: 50px !important;
      }
    }
  }
}
