.titleSection {
  text-align: center;
  color: var(--textColor);
  width: 90%;
  margin: auto;
}
.products {
  padding: 100px 0;

  .productsSearchBox {
    margin: 25px auto;
    width: 80%;
    justify-content: space-evenly;
    input {
      width: 60%;
      padding: 20px 25px;
      background-color: var(--bgColorTransparent);
      &::placeholder {
        color: var(--textColor);
      }
    }
    & > div {
      width: 30%;
    }
  }
  p {
    text-align: center;
    color: var(--textColor);
  }
  .pr-cards {
    justify-content: space-evenly;
    margin: 20px auto;
    gap: 25px;
    width: 80%;

    .pr-card-section {
      background-color: var(--primaryColor);
      border-radius: 12px;
      overflow: hidden;
      transition: all 0.3s ease;

      &:hover {
        box-shadow: var(--primaryColor) 0px 2px 8px 0px;
        transform: scale(1.05);
      }

      cursor: pointer;
      display: flex;
      flex-direction: column;
      width: 30%;
      align-items: flex-start;
      color: var(--textColor);

      section {
        gap: 10px 0;
        width: 100%;
        position: relative;

        img {
          height: 350px;
          width: 100%;
          object-fit: cover;
        }
        & > span {
          position: absolute;
          background-color: var(--bgColor);
          color: var(--textColor);
          padding: 10px 15px;
          right: 0px;
          top: 0px;
          border-bottom-left-radius: 12px;
        }
      }
      .timerDiv {
        background-color: var(--bgColor);
        color: var(--textColor);
        padding: 10px 15px;
        bottom: 15px;
        left: 15px;
        border-radius: 8px;
      }
      .prcs-texts {
        justify-content: space-between;
        padding: 10px 15px;
        p,
        b {
          padding: 0 15px;
        }
        p {
          text-align: left;
          font-size: 25px;
        }
        b {
          font-size: 30px;
        }
      }
    }
  }
}

.emailNeededMiddleware {
  padding: 150px 0;
  width: 80%;
  margin: auto;
  color: var(--textColor);
}

@media screen and (min-width: 758px) and (max-width: 1200px) {
  .products {
    .pr-cards {
      .pr-card-section {
        width: 45%;
      }
    }
  }
}
@media screen and (max-width: 758px) {
  .products {
    .productsSearchBox {
      gap: 15px 0;
      input {
        width: 100%;
      }
      & > div {
        width: 100%;
      }
    }
    .pr-cards {
      width: 90%;
      .pr-card-section {
        width: 100%;
      }
    }
  }
}
