.sidebar {
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 25px 0;
  padding: 50px 30px;
  width: $sidebarWidth;
  background-color: var(--primaryColor);
  color: white;
  z-index: 9999999;
  .sidebar-logo {
    width: 90%;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
    }
  }
  .sidebar-links {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
    a {
      font-weight: bold;
      &.active {
        background-color: white;
        color: var(--primaryColor);
        border-radius: 8px;
        padding: 8px 12px;
      }
    }
  }
  button {
    margin-top: auto;
    color: white;
    border-bottom: solid 2px white;
    font-weight: bolder;
  }

  .mobileSidebarToggler {
    position: absolute;
    right: -50px;
    width: 50px;
    height: 50px;
    top: 10px;
    background-color: var(--primaryColor);
    color: white;
    display: none;
    cursor: pointer;
    font-size: 30px;
  }
  .themeTogglerForSidebar {
    cursor: pointer;
    background-color: var(--primaryColor);
    position: absolute;
    left: $sidebarWidth;
    width: 50px;
    height: 50px;
    top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 1024px) {
  .sidebar {
    left: calc(-1 * $sidebarWidth);
    transition: all 0.6s;

    &.sidebarVisibleOnMobile {
      left: 0;
    }
    &.sidebarVisibleOnMobile ~ .connectedMain {
      transform: translateX($sidebarWidth);
    }
  }

  .mobileSidebarToggler {
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
}
