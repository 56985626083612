.about {
  width: 100%;
  padding: 100px 0;
  color: var(--textColor);
}

.about-section {
  display: flex;
  justify-content: space-around;
  align-items: center;

  .Abs-img {
    width: 35%;
    img {
      width: 100%;
    }
    h2 {
      text-align: center;
      color: var(--primaryColor);
      font-size: 40px;
    }
  }
  .Abs-texte {
    width: 45%;
  }
}
.Ab-bannière1 {
  display: flex;
  justify-content: space-evenly;

  .Ab-b1-text {
    margin: 50px auto;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    section {
      display: flex;
      justify-content: space-around;
      align-items: center;
      span {
        width: 80%;
        h4 {
          font-weight: 50px;
          font-size: 20px;
          color: var(--primaryColor);
        }
        p {
          font-size: 15px;
        }
      }
      svg {
        font-size: 100px;
        color: var(--primaryColor);
      }
    }
  }
  .Ab-b1-img {
    width: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      margin: auto;
    }
  }
}
.about-bottom {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  width: 80%;
  margin: auto;
  h4 {
    text-align: center;
    font-size: 30px;
    color: var(--primaryColor);
  }
  p {
    text-align: center;
    font-size: 18px;
    padding-top: 20px;
  }
  .ab-b-section {
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
    svg {
      font-size: 30px;
      color: var(--primaryColor);
    }
    .abb-section-left {
      width: 45%;
      span {
        display: flex;
        font-size: 18px;
        justify-content: space-around;

        align-items: center;
        p {
          width: 80%;
          text-align: start;
        }
      }
    }

    hr {
      width: 10px;
      background-color: var(--primaryColor);
      border: 0;
    }
    .abb-section-right {
      width: 45%;
      display: flex;
      flex-direction: column;

      span {
        display: flex;
        font-size: 18px;
        justify-content: space-around;

        align-items: center;

        p {
          width: 80%;
          text-align: start;
        }
      }
    }
  }
}

@media screen and (max-width: 752px) {
  .about {
    .about-section {
      display: flex;
      flex-direction: column;
      width: 100%;

      div {
        width: 90%;
        margin: auto;
        padding: 30px 10px;
      }
    }
    .Ab-bannière1 {
      width: 80%;
      margin: auto;
      display: flex;
      flex-direction: column;

      .Ab-b1-text {
        width: 100%;
        display: flex;
        flex-direction: column;

        section {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;

          span {
            width: 100%;
            padding: 30px 0;
          }

          img {
            width: 30%;
          }
        }
      }
    }
    .about-bottom {
      width: 100%;

      p {
        text-align: center;
        width: 70%;
        margin: auto;
      }

      .ab-b-section {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;

        .abb-section-left {
          width: 100%;
        }

        hr {
          visibility: hidden;
        }

        .abb-section-right {
          width: 100%;
        }
      }
    }
  }
}
