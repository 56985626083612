.ProductsInfo {
  padding: 100px 0;
  color: var(--textColor);
  justify-content: space-evenly;
  h1 {
    text-align: center;
  }
  & > div {
    align-items: flex-start;
  }
  .PI-img {
    width: 45%;
    display: flex;
    flex-direction: column;
    .slick-current {
      img {
        border: solid 4px var(--primaryColor);
      }
    }

    .big-image {
      width: 100%;
      height: 400px;

      display: flex;
      justify-content: center;
      border-radius: 10px;
      img {
        height: 100%;
        margin: auto;

        overflow: hidden;
        object-fit: contain;
        object-position: center;
      }
    }

    .small-image {
      width: 100px;
      height: 100px;
      overflow: hidden !important;
      cursor: pointer;
      display: flex !important;
      justify-content: center;

      img {
        border-radius: 10px;
      }
    }
  }
  .PI-text {
    width: 35%;
    .PI-text-prix {
      justify-content: flex-start;
      align-items: flex-start;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0;
      width: 100%;
      span {
        font-size: 18px;
      }
      b {
        font-size: 20px;
      }
      a {
        display: flex;
        align-items: center;
        color: var(--primaryColor);
        font-size: 25px;
      }
    }
  }
  .pi-desc {
    width: 85%;
    margin: 25px auto;
  }
}

.allTestimonials {
  color: var(--textColor);
  padding: 100px 0;
  width: 80%;
  margin: auto;
  gap: 50px 0;
  h3 {
    margin-bottom: 25px;
  }
  & > section {
    & > div {
      gap: 25px;
      justify-content: space-evenly;
      img,
      .waveAudioPlayer,
      .t-text,
      video {
        width: 22%;
      }
    }
  }
  .at-screens {
    img {
      border: solid 4px black;
      border-radius: 10px;
    }
  }
  .at-text {
  }
  .at-audio {
    .waveAudioPlayer {
      width: 100%;
      justify-content: space-evenly;
      span {
        width: 30px;
        height: 30px;
        background-color: var(--primaryColor);
        color: var(--textColor);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }
      & > div {
        width: calc(100% - 50px);
      }
    }
  }

  .clientNameAndPic {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    gap: 0 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    img {
      width: 60px;
      height: 60px;
      border-radius: 100%;
    }
  }
}

@media screen and (max-width: 758px) {
  .ProductsInfo {
    width: 90%;
    margin: auto;
    .PI-img {
      width: 100%;
      .big-image {
        height: 300px;
      }
    }
    .PI-text {
      width: 100%;
    }
    .pi-desc {
      width: 100%;
    }
  }

  .allTestimonials {
    width: 90%;

    & > section {
      & > div {
        img,
        .waveAudioPlayer,
        .t-text,
        video {
          width: 98%;
        }
      }
    }
  }
}
