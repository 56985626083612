.dataManager {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 25px 0;
  color: var(--textColor);
  .dm-dataList {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 20px;
    article {
      width: 48%;
      background-color: rgba(0, 0, 0, 0.1);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      padding: 15px;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 10px 0;
      p {
        text-wrap: wrap;
        word-break: break-all;
      }
      .multipleImgs {
        gap: 5px;
        justify-content: center;
        label {
          width: 48%;
          height: 100px;
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          input {
            display: none;
          }
          span {
            background-color: var(--primaryColor);
            padding: 5px 10px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
      }
      img {
        width: 100%;
        height: 300px;
        object-fit: contain;
      }
      video,
      audio {
        width: 100%;
        margin-top: 10px;
      }
      strong {
        color: var(--primaryColor);
      }
      div {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 10px;
        button {
          color: var(--textColor);
          padding: 5px 10px;
          cursor: pointer;
        }
      }
    }
  }

  .counter {
    color: var(--primaryColor);
    border: solid 2px var(--primaryColor);
    font-size: 35px;
    width: 40px;
    height: 40px;
    border-radius: 100%;

    text-align: center;
    line-height: 40px;
  }

  .dm-dataList2 {
    display: flex;
    flex-direction: column;
    gap: 25px;
    p {
      word-break: break-all;
    }
    article {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
      background-color: rgba(0, 0, 0, 0.1);
      padding: 50px 30px;
      display: flex;
      flex-direction: column;
    }
    & > img,
    video {
      width: 100%;
    }
    img {
      margin-top: 15px;
    }
    embed {
      height: 100vh;
    }
  }
}

.updateBtn,
.deleteBtn {
  color: white !important;
  border-radius: 20px;
  margin-top: 15px;
  padding: 12px 25px !important;
  cursor: pointer;
}
.updateBtn {
  background-color: rgb(44, 44, 163);
}
.deleteBtn {
  background-color: red;
}

.newsLetter {
  width: 80%;
  margin: 25px auto;
  padding: 25px 30px;
  background-color: var(--primaryColor);
  border-radius: 20px;
  justify-content: space-around;
  align-items: center;
  section {
    width: 45%;
  }
  .nl-text {
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    h4 {
      font-size: 25px;
    }
    p {
      font-size: 18px;
      text-align: left;
    }
  }
  .nl-input {
    form {
      display: flex;
      flex-direction: row;
      background-color: white;
      padding: 10px;
      border-radius: 50px;

      input {
        width: 60%;
        border-radius: 50px;
        border: none !important;
        &::placeholder {
          font-size: 18px;
          color: #000;
        }
      }
      button {
        width: 40%;
        background-color: black;
        color: white;
        border-radius: 50px;
      }
    }
  }
}

.dashboard {
  padding: 50px 0;
  display: flex;
  justify-content: space-evenly;
  .fakeWatch {
    height: 100px;
    border-radius: 20px;
    overflow: hidden;
    position: absolute;
    background-color: gray;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    // width: 50%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    p {
      height: 100%;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      padding: 0 30px;
      text-wrap: nowrap;
    }
    p:first-child {
      background-color: var(--textColor);
      color: var(--bgColor);
      width: 40%;
    }
    p:last-child {
      background-color: var(--bgColor);
      color: var(--textColor);
      width: 60%;
      gap: 0 5px;
    }
  }
  & > section {
    position: relative;
    width: 45%;
    &:first-child {
      display: flex;
      img {
        width: 100%;
      }
    }
  }
}
.appLoader {
  height: 100vh;
  width: 100%;
  background-color: var(--primaryColor);
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}

.leadPage {
  padding: 100px 0;
  & > section {
    gap: 50px 0;
    width: 80%;
    margin: auto;
    & > div {
      width: 100%;
    }
    .lp-paragraph {
      color: var(--textColor);
      word-break: keep-all;
      hyphens: none;
    }
    .lp-gallery {
      // columns: 2;
      // width: 100%;

      img {
        object-fit: cover;
        width: 40%;
      }
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      justify-content: space-evenly;
    }

    .lp-video {
      max-width: 100%;
      display: flex;
      justify-content: center;

      video {
        border: solid 5px var(--primaryColor);
        border-radius: 10px;
        overflow: hidden;
        // width: 100%;
        height: auto;
        object-fit: fill;
      }
    }

    .lp-pdf {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 15px 0;
      img {
        max-width: 80%;
        min-width: 60%;
      }
      // embed {
      //   height: 100vh;
      //   width: 100%;
      // }
    }

    .lp-reviews {
    }

    .lp-button {
      width: 100%;
      text-align: center;
    }
  }
}

.legal {
  width: 90%;
  margin: 0 auto;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 50px 0;
  h1,
  h2 {
    color: var(--primaryColor);
  }
  p,
  ul {
    color: var(--textColor);
  }
}
@media screen and (max-width: 1024px) {
  .dashboard {
    flex-direction: column;
    gap: 25px 0;
    & > section {
      width: 100%;
    }
  }
  .dataManager {
    .dm-dataList {
      article {
        width: 90%;
      }
    }
  }
  .newsLetter {
    width: 95%;
    flex-direction: column;
    gap: 20px 0;
    section {
      width: 100%;
    }
  }

  .leadPage {
    & > section {
      width: 95%;
      .lp-gallery {
        img {
          width: 100%;
        }
      }
    }
  }
}
